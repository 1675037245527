import React, { useState, useEffect } from "react"
import Layout from "../components/layout/Layout"
import { graphql, Link } from "gatsby"
import ItemDisplayList from "../components/ItemDisplayList"
import Seo from "../components/SEO"
import Title from "../components/Title"
import SearchBar from "../components/SearchBar"

const SearchPage = ({ data, location }) => {
  const allPosts = data.allMdx.nodes
  const params = new URLSearchParams(location.search)
  const query = params.get("s")

  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(true)

  const handleData = () => {
    // return all filtered posts
    const filteredData = allPosts.filter(post => {
      // destructure data from post frontmatter

      const { description, title, tags, categories } = post.frontmatter
      if (query === null) {
        return false
      } else {
        return (
          description.toLowerCase().includes(query.toLowerCase()) ||
          title.toLowerCase().includes(query.toLowerCase()) ||
          (tags &&
            tags
              .join("") // convert tags from an array to string
              .toLowerCase()
              .includes(query.toLowerCase())) ||
          (categories &&
            categories
              .join("") // convert categories from an array to string
              .toLowerCase()
              .includes(query.toLowerCase()))
        )
      }
    })
    setFilteredData(filteredData)
    setLoading(false)
  }
  useEffect(() => {
    handleData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasSearchResults = filteredData && query !== ""
  const posts = hasSearchResults ? filteredData : ""

  return (
    <Layout>
      <Seo title="Search" />
      <section className="blog-page">
        {!query && (
          <div>
            <Title title="Search" />
            <SearchBar />
          </div>
        )}
        {query && (
          <div>
            <Title title="Search result" />
            {loading && <div>Searching...</div>}
            {posts.length === 0 && !loading && (
              <div className="error-container">
                <p>Sorry. We couldn’t find what you were looking for.</p>
                <Link to="/" className="btn">
                  Back to Homepage
                </Link>
              </div>
            )}
            <ItemDisplayList items={posts} />
          </div>
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      nodes {
        frontmatter {
          menuTitle
          title
          description
          categories
          tags
        }
        id
        slug
      }
    }
  }
`

export default SearchPage
